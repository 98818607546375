import moment from 'moment'

const customFunctions = {
  setPhoneNumber(phone_code, phone) {
    if (!phone) {
      return ''
    }

    if (
      phone_code !== '' &&
      phone_code !== null &&
      phone_code.startsWith('+') === false
    ) {
      phone_code = '+' + phone_code
    }
    return (
      (phone_code !== null ? phone_code : '') +
      ' ' +
      (phone !== null ? phone : '-')
    )
  },
  moneyFix(val, pieceCount) {
    if (val) {
      val = parseFloat(val)
      if (val > 0) {
        val = val.toFixed(pieceCount).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.').replace(',00', '')

      }
    }
    return '-'
  },
  /* 
  let date = '2021-05-30T11:34:56+03:00'
  let utcOffset = '+04:00'
*/
  setMomentWithFormat(date, format, utcOffset) {
    if (date) {
      if (!utcOffset) {
        //control utcOffset
        if (date.includes('+')) {
          var splt = date.split('+')
          utcOffset = `+${splt[1]}`
        }
      }

      if (!format) { format = 'L LT' }

      if (utcOffset) {
        return moment(date).utcOffset(utcOffset).format(format)
      }
      return moment(date).format(format)
    }
    else
      return '-'
  },
}

export default customFunctions
