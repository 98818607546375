const ID_TOKEN_KEY = 'plusclouds_account_token'
const CURRENT_USER_KEY = 'plusclouds_current_user'
const USER_VERIFICATION = 'user_verification'
const REGISTERED_USER = 'user_registered'
const SKIP_WELCOME = 'skip_welcome'
const CURRENT_LANGUAGE_KEY = 'currentLanguage'
const CURRENT_ROUTE_KEY = 'currentRoute'
const MATOMO_VISITOR_ID = 'plusclouds_matomo_visitor_id'

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY)
}

export const getCurrentUser = () => {
  return JSON.parse(window.localStorage.getItem(CURRENT_USER_KEY))
}

export const getUserVerification = () => {
  return JSON.parse(window.localStorage.getItem(USER_VERIFICATION))
}

export const getUserRegistered = () => {
  return JSON.parse(window.localStorage.getItem(REGISTERED_USER))
}

export const getSkipWelcome = () => {
  return window.localStorage.getItem(SKIP_WELCOME)
}

export const saveToken = payload => {
  window.localStorage.setItem(
    ID_TOKEN_KEY,
    `${payload.token_type} ${payload.access_token}`
  )
  window.localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(payload))
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
  window.localStorage.removeItem(CURRENT_USER_KEY)
  window.localStorage.removeItem(CURRENT_LANGUAGE_KEY)
}

export const saveUserVerification = payload => {
  window.localStorage.setItem(USER_VERIFICATION, JSON.stringify(payload))
}

export const saveUserRegistered = payload => {
  window.localStorage.setItem(REGISTERED_USER, JSON.stringify(payload))
}

export const saveSkipWelcome = payload => {
  window.localStorage.setItem(SKIP_WELCOME, payload)
}

export const destroyUserVerification = () => {
  window.localStorage.removeItem(USER_VERIFICATION)
}

export const destroyUserRegistered = () => {
  window.localStorage.removeItem(REGISTERED_USER)
}

export const destroySkipWelcome = () => {
  window.localStorage.removeItem(SKIP_WELCOME)
}

export const getCurrentRoute = () => {
  return window.localStorage.getItem(CURRENT_ROUTE_KEY)
}
export const setCurrentRoute = payload => {
  window.localStorage.setItem(CURRENT_ROUTE_KEY, payload)
}
export const destroyCurrentRoute = () => {
  window.localStorage.removeItem(CURRENT_ROUTE_KEY)
}
export const getVisitorId = () => {
  return window.localStorage.getItem(MATOMO_VISITOR_ID)
}
export const setVisitorId = payload => {
  window.localStorage.setItem(MATOMO_VISITOR_ID, payload)
}

export default {
  getToken,
  getCurrentUser,
  saveToken,
  destroyToken,
  saveUserVerification,
  saveUserRegistered,
  destroyUserVerification,
  getUserVerification,
  getUserRegistered,
  destroyUserRegistered,
  saveSkipWelcome,
  getSkipWelcome,
  destroySkipWelcome,
  getCurrentRoute,
  setCurrentRoute,
  destroyCurrentRoute,
  getVisitorId,
  setVisitorId
}
