import Vue from 'vue'
import Router from 'vue-router'

import store from '@/core/services/store'
import { VERIFY_AUTH } from '@/core/services/store/auth.module'
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module'
import MLService from '@/ml'

Vue.use(Router)

// Import middleware
import verifyUser from '@/middleware/verifyUser'
import verifyEnv from '@/middleware/verifyEnv'
import verifyRoute from '@/middleware/verifyRoute'
import verifyLoggedUser from '@/middleware/verifyLoggedUser'
import matomoUser from '@/middleware/matomoUser'

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/general',
      component: () => import('@/view/layout/Layout'),
      beforeEnter(to, from, next) {
        document.getElementById("preloader-icon").style.display = "inline";
        MLService.setFirstLanguage().then(function () {
          next()
          setTimeout(() => document.getElementById("preloader-icon").style.display = "none", 200)
        })
      },
      children: [
        {
          path: '/general',
          name: 'general',
          meta: {
            title: 'Genel',
            titleKey: 'general',
            middleware: [verifyUser, verifyLoggedUser, verifyEnv, matomoUser],
          },
          component: () => import('@/view/pages/general'),
        },
        {
          path: '/payment',
          name: 'payment',
          meta: {
            title: 'Ödeme',
            titleKey: 'payment',
            middleware: [verifyUser, verifyLoggedUser, verifyEnv, matomoUser],
          },
          component: () => import('@/view/pages/payment'),
        },
        {
          path: '/support',
          name: 'support',
          meta: {
            title: 'Destek',
            titleKey: 'support',
            middleware: [verifyUser, verifyLoggedUser, verifyEnv, matomoUser],
          },
          component: () => import('@/view/pages/support'),
        },
        {
          path: '/personal-access-token',
          name: 'personalAccessTokens',
          meta: {
            title: 'Kişisel Giriş Anahtarları',
            titleKey: 'personalAccessTokens',
            middleware: [verifyUser, verifyLoggedUser, verifyEnv, matomoUser],
          },
          component: () => import('@/view/pages/personalAccessToken'),
        },
        {
          path: '/team-management',
          name: 'team-management',
          meta: {
            title: 'Team Management',
            titleKey: 'teamManagement',
            middleware: [verifyUser, verifyLoggedUser, verifyEnv, matomoUser],
          },
          component: () => import('@/view/pages/teamManagement'),
        },
        {
          path: '/team-management/:id',
          name: 'team-management-detail',
          meta: {
            title: 'Team Management Detail',
            titleKey: 'teamManagementDetail',
            middleware: [verifyUser, verifyLoggedUser, verifyEnv, matomoUser],
          },
          component: () => import('@/view/pages/teamManagement/Detail'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/Auth'),
      beforeEnter(to, from, next) {
        MLService.setBrowserLanguage().then(function () { next() })
      },
      children: [
        {
          path: '/login',
          name: 'login',
          meta: {
            title: 'Giriş Yap',
            titleKey: 'login',
            middleware: [verifyEnv, verifyRoute, matomoUser],
          },
          component: () => import('@/view/pages/auth/Login'),
        },
        {
          path: '/register',
          name: 'register',
          meta: {
            title: 'Kayıt Ol',
            titleKey: 'register',
            middleware: [verifyEnv, matomoUser],
          },
          component: () => import('@/view/pages/auth/Register'),
        },
        {
          path: '/password-reset',
          name: 'passwordReset',
          meta: {
            title: 'Şifre Sıfırla',
            titleKey: 'passwordReset',
            middleware: [verifyEnv, matomoUser],
          },
          component: () => import('@/view/pages/auth/PasswordReset'),
        },
      ],
    },
    {
      // the 404 route, when none of the above matches
      path: '/callback',
      name: 'callback',
      meta: {
        title: 'Callback',
        titleKey: 'callback',
        middleware: [verifyEnv, matomoUser],
      },
      component: () => import('@/view/pages/callback/Callback.vue'),
      beforeEnter(to, from, next) {
        MLService.setBrowserLanguage().then(function () { next() })
      },
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      meta: {
        title: 'Sayfa Bulunamadı',
        titleKey: 'pageNotFound',
        middleware: [matomoUser],
      },
      component: () => import('@/view/pages/error/Error-1.vue'),
      beforeEnter(to, from, next) {
        MLService.setBrowserLanguage().then(function () { next() })
      },
    },
  ],
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}


router.beforeEach((to, from, next) => {

  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next)

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG)

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      store,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }
  else {
    return next()
  }
})

export default router
