import { PROD_ENV } from '@/core/services/store/envProd.module'
import envProd from '@/env/prod.env.json'

export default function verifyEnv({ next, store }) {
  if(process.env.NODE_ENV == 'production') {
    store.dispatch(PROD_ENV, envProd)
  }

  return next()
}
