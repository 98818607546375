
// mutation types
export const SET_CUSTOMHEADERPARAMS = "setCustomHeaderParams";
export const CLEAR_CUSTOMHEADERPARAMS = "clearCustomHeaderParams"

export default {
  state: {
    customHeaderParams: []
  },
  getters: {
    customHeaderParams(state) {
      return state.customHeaderParams;
    },
  },
  actions: {
    [SET_CUSTOMHEADERPARAMS](state, payload) {
      state.commit(SET_CUSTOMHEADERPARAMS, payload);
    },
    [CLEAR_CUSTOMHEADERPARAMS](state) {
      state.commit(CLEAR_CUSTOMHEADERPARAMS);
    },
  },
  mutations: {
    [SET_CUSTOMHEADERPARAMS](state, payload) {
      state.customHeaderParams = payload;
    },
    [CLEAR_CUSTOMHEADERPARAMS](state) {
      state.customHeaderParams = '';
    }
  }
};
