export default {
  hello: 'Merhaba',
  welcomeToPlusClouds: "Plusclouds'a Hoşgeldiniz",
  welcomeToPlusGrowth: "Plusgrowth'a Hoşgeldiniz",
  welcomeText: 'Hoşgeldin',
  welcomeContentPlusClouds:
    'PlusClouds dünyasına hoş geldiniz, burada olmanızdan çok memnunuz. Görüyoruz ki, bulut teknolojileriyle ilgileniyorsunuz. İhtiyacınızı karşılayacak bulut çözümlerine sahip olabilmeniz için isterseniz bize ulaşın, biz de bulut çözümlerinde başlayacağınız keşifte size en uygun yollar yaratalım.',
  welcomeContentPlusGrowth:
    'PlusGrowth dünyasına hoş geldiniz, burada olmanızdan çok memnunuz. Görüyoruz ki, bulut teknolojileriyle ilgileniyorsunuz. İhtiyacınızı karşılayacak bulut çözümlerine sahip olabilmeniz için isterseniz bize ulaşın, biz de bulut çözümlerinde başlayacağınız keşifte size en uygun yollar yaratalım.',
  hereIsOurNumber: 'İşte bize ulabileceğiniz numara:',
  niceWorkingDay: 'İyi çalışmalar,',
  sincerely: 'Saygılarımızla.',
  welcomeExperienceText:
    'Deneyiminizi iyileştirmek için bir kaç onayınıza ihtiyacımız var.',
  selectLanguage: 'Dil Seç',
  mailVerification: 'Mail Onayı',
  gsmVerification: 'Gsm Onayı',
  identityVerification: 'TC Kimlik Onayı',
  welcomePermissionText:
    'Dilerseniz bu adımları geçerek destek ekibimizin sizinle iletişime geçmesine ve yardımcı olmasına izin verin.',
  skipSteps: 'Adımları Atla',
  customerRepresentative: 'Müşteri Temsilciniz',
  verifyYourEmailAddress: 'Email adresinizi doğrulayın',
  confirmEmailInfoText:
    'Lütfen email adresinize gönderdiğimiz kodu girerek mailinizi aktif ediniz.',
  confirmEmailConfirmationCode: 'Onay kodu ulaşmadı mı?',
  tryAgain: 'Tekrar Dene',
  confirm: 'Onayla',
  confirmationCode: 'Onay kodu',
  sendConfirmationCode: 'Onay kodu gönder',
  saveAndSendConfirmationCode: 'Kaydet ve onay kodu gönder',
  verificationYourGSM: 'GSM numaranızı doğrulayın',
  pleaseEnterGSMNo: 'Lütfen GSM Numaranızı giriniz',
  verifyYourIdentityNumber: 'TC kimlik numaranızı doğrulayın',
  verifyYourIdentityNumberInfoText:
    'Lütfen T.C. Kimlik numaranızı girerek hesabınızı onaylayın',
  letUsCallYou: 'Sizi Arayalım',
  letUsCallYouInfoText:
    'Görüyoruzki adımları geçmek istedin. Hiç problem değil. Telefon numaranızı bırakmanız halinde destek ekibimiz sizinle iletişime geçecektir.',
  chooseDay: 'Gün Seçimi',
  chooseTime: 'Saat Seçimi',
  success: 'Başarılı',
  successful: 'Başarılı!',
  error: 'Hata',
  letUsCallYouSuccessText:
    'Talebinizi aldık. Destek ekibimiz en kısa sürede sizinle iletişime geçerek gerekli desteği sağlayacaktır.',
  userProfile: 'Kullanıcı Profili',
  signOut: 'Çıkış Yap',
  general: 'Genel',
  payment: 'Ödeme',
  creditCard: 'Kredi Kartı',
  creditCards: 'Kredi Kartları',
  noCreditCardInfo:
    'Henüz banka kredi kartı bilgilerinizi bizimle paylaşmadınız!',
  addPaymentMethod: 'Ödeme Yöntemi Ekle',
  addNewCard: 'Yeni Kart Ekle',
  cardType: 'KART TİPİ',
  cardName: 'AD SOYAD',
  ending: 'S.K. TARİHİ',
  isValid: 'DOĞRULANMIŞ MI?',
  isDefault: 'VARSAYILAN',
  default: 'Varsayılan',
  no: 'Hayır',
  valid: 'Doğrulanmış',
  invalid: 'Doğrulanmamış',
  verifyCreditCard: 'Lütfen kredi kartınızı doğrulayın.',
  noDataAvailable: 'Gösterilecek bilgi bulunamadı',
  verifyCreditCardTitle: 'Hesap Doğrulama',
  verifyCreditCardSubTitle: 'Kartın size ait olduğunu doğrulamak için kartınızdan çekilen 0-1 tl arasındaki değeri aşağıdaki alana giriniz. (Çekilen miktar hesabınıza yüklenecektir.)',
  verifyCreditCardSecondSubTitle:'Doğrulama işlemi 1 dakika içerisinde yapılmalıdır. İşleminiz bu süreyi geçerse kartınızı silip, tekrardan tanımlamanız gerekmektedir.',
  amount: 'Miktar',
  verify: 'Doğrulayın',
  invalidAmount: 'Geçersiz Tutar',
  verifyCreditCardSuccess: 'Kredi kartınız başarı ile doğrulandı!',
  anErrorHasOccurred:
    'Bir hata oluştu, lütfen daha sonra tekrar deneyin veya yöneticinize başvurun.!',
  cardHolderName: 'Kart Üzerindeki İsim',
  cardNumber: 'Kart Numarası',
  cardInformation: 'Kart Bilgileri',
  expDate: 'Son Kullanım Tarihi',
  cvv: 'CVV',
  addCard: 'Kart Ekle',
  invalidCreditCardHolderName: 'Kredi Kart Geçersiz Ad',
  invalidCreditCardNumber: 'Geçersiz Kredi Kartı Numarası',
  invalidExpirationDate: 'Geçersiz Son Kullanma Tarihi',
  invalidCVCNumber: 'Geçersiz CVC Numarası',
  addedCreditCardSuccess: 'Kredi Kartınız Başarıyla Eklendi! Doğrulama işlemini 1 dakika içerisinde yapmanız gerekmektedir.',
  billingHistory: 'Ödeme Listesi',
  pay: 'Öde',
  payNow: 'Şimdi Öde',
  paid: 'Ödenmiş',
  payInvoice: 'Fatura Öde',
  selectedInvoiceAmount: 'Seçilen Fatura Tutarı',
  pleaseSelectYourCreditCard: 'Lütfen kredi kartınızı seçin',
  yourBillingPaymentIsSuccessful: 'Fatura ödemeniz başarılı!',
  memberSince: 'Member since',
  accountType: 'Hesap Tipi',
  yourAccountType: 'Hesap tipiniz',
  updateAccountTypeText: 'Lütfen hesap türünüzü seçin veya güncelleyin',
  pleaseSelectAccountType: 'Lütfen hesap türünü seçin',
  saveChanges: 'Değişiklikleri Kaydet',
  generalSettings: 'Genel Ayarlar',
  basic: 'Basit',
  nameSurname: 'Ad Soyad',
  enterNameSurname: 'Ad Soyad Girin',
  birthdate: 'Doğum Tarihi',
  identityNumber: 'T.C. Kimlik Numarası',
  okey: 'Tamam',
  password: 'Şifre',
  enterYourCurrentPassword: 'Mevcut şifrenizi girin',
  emailAddress: 'Email Adresi',
  enterEmailAddress: 'Email adresinizi girin',
  newPassword: 'Yeni Şifre',
  enterYourNewPassword: 'Yeni şifrenizi girin',
  newPasswordAgain: 'Yeni Şifre Tekrarı',
  enterYourNewPasswordAgain: 'Yeni şifrenizi tekrar girin',
  gender: 'Cinsiyet',
  pleaseSelectGender: 'Lütfen cinsiyetinizi seçin',
  birthDate: 'Doğum Tarihi',
  preferences: 'Tercihler',
  language: 'Dil',
  pleaseSelect: 'Lütfen Seçiniz',
  currency: 'Para Birimi',
  updatedGeneralSettingsSuccess: 'Ayarlar başarı ile güncellendi!',
  newHere: 'Yeni Misin?',
  createAnAccount: 'Kayıt Ol',
  loginWithPlusClouds: 'PlusClouds ile Giriş Yap',
  loginWithPlusGrowth: 'PlusGrowth ile Giriş Yap',
  pleaseWaitingRedirectToBack:
    "Lütfen bekleyin, Leo Console'a geri yönlendiriliyorsunuz.",
  alreadyHaveAnAccount: 'Zaten hesabınız var mı?',
  signIn: 'Giriş Yap',
  fullname: 'Ad Soyad',
  fullnameIsRequired: 'Ad Soyad Zorunlu',
  emailIsRequiredInfoText: 'E-posta gerekli ve geçerli bir e-posta adresi.',
  passwordIsRequired: 'Şifre Zorunlu.',
  passwordConfirmation: 'Şifre Tekrarı',
  passwordsMustBeIdentical: 'Parolalar aynı olmalıdır.',
  createFreeAccount: 'Ücretsiz Hesap Oluştur',
  score: 'Skor',
  fair: 'Zayıf',
  good: 'İyi',
  veryStrong: 'Mükemmel',
  eightCharactersMinimum: 'Minimum 8 karakter',
  oneNumber: 'Bir sayı',
  oneSpecialCharacter: 'Bir özel karakter',
  oneUpper: '1 büyük harf',
  oneLower: '1 küçük harf',
  passwordReset: 'Şifre Sıfırla',
  didYouComeWrong: 'Yanlış mı geldiniz?',
  confirmCodeForEmail: 'E-postanıza gelen onay kodu',
  confirmCodeForPhone: 'Telefonunuza gelen onay kodu',
  confirmCodeForEmailRequired: 'E-posta adresinize gelen onay kodu gerekli',
  confirmCodeForPhoneRequired: 'Telefonunuza gelen onay kodu gerekli',
  phoneIsRequired: 'Telefon alanı zorunlu',
  phoneCodeIsRequired: 'Telefon kodu alanı zorunlu',
  confirmCodeForEmailIsAccepted: 'E-postanıza gelen onay kodu kabul edildi',
  confirmCodeForPhoneIsAccepted: 'Telefonunuza gelen onay kodu kabul edildi',
  captcha: 'Güvenlik kodu',
  subscribe: 'Abone Ol',
  subscription: 'Abonelik',
  piece: 'Adet',
  infrastructureResource: 'Altyapı Kaynağı',
  registered: 'Taahhütlü',
  unregistered: 'Taahhütsüz',
  packets: 'Paketler',
  monthlyPackagesWithandWithoutCommitment: 'Taahhütlü ve taahhütsüz aylık paketler',
  monthly: 'Aylık',
  discount: 'İndirim',
  free: 'Ücretsiz',
  cancel: 'Vazgeç',
  youMustAcceptTerm: 'Kullanım koşullarını kabul etmeniz gerekmektedir.',
  acceptTermWithUrl: 'Kullanım şartlarını okudum ve kabul ediyorum.',
  confirmOfPurchase: 'Satın alma onayı',
  yourPaymentOptions: 'Ödeme seçenekleriniz',
  yourPackage: 'Paketiniz',
  youCanMakePurchaseByChoosingCards: 'Aşağıda bulunan kartlarınızdan birini seçerek satın alma işleminizi gerçekleştirebilirsiniz.',
  freeCloudService: 'Ücretsiz Cloud Servisi',
  teknosaBannerSubtitle: 'Teknosacell müşterilerine özel, ücretsiz, mobil cihazınızdaki ve bilgisayarınızdaki tüm dosyaları daimi erişilebilir bulutta güvenle saklayın.',
  youMustHaveLeastOneCreditCard: 'İşlem yapabilmek için en az bir kredi kartınızın tanımlı olması gerekmektedir.',
  allTickets: 'Tüm Talepler',
  open: 'Açık',
  pending: 'Bekliyor',
  closed: 'Kapalı',
  searchForPerson: 'Kişi ara',
  allCategories: 'Tüm Kategoriler',
  category: 'Kategori',
  selectCategory: 'Kategori Seçiniz',
  myTickets: 'Taleplerim',
  openTicket: 'Destek Talebi Oluştur',
  ticketNumber: 'Talep Numarası',
  latestActivityOnYourAccountIsDisplayed: 'Hesabınızdaki en son etkinlik geçmişi aşağıda gösterilmektedir!',
  priority: 'Öncelik',
  title: 'Başlık',
  createdDate: 'Oluşturulma Tarihi',
  status: 'Durum',
  support: 'Destek',
  subject: 'Konu',
  priority: 'Öncelik',
  selectPriority: 'Öncelik seçiniz',
  message: 'Mesaj',
  attachment: 'Ek Dosya',
  chooseFile: 'Dosya seç..',
  dropFileHere: 'Dosyayı buraya bırakınız..',
  low: 'DÜŞÜK',
  medium: 'ORTA',
  high: 'YÜKSEK',
  addedTicketSuccess: 'Bilet Başarıyla Eklendi!',
  addedAnswerSuccess: 'Cevap Başarıyla Eklendi!',
  writeSomething: 'Bir şey yaz...',
  send: 'Gönder',
  writeReplyOrDragFilesHere: 'Cevabınızı yazın veya dosyaları buraya sürükleyin',
  getFreeStash: 'Ücretsiz Stash Sahibi Olun',
  contactMe: 'BANA ULAŞIN',
  canWeHelpYou: 'Size nasıl yardımcı olabiliriz?',
  contactUs: 'Bana Ulaşın',
  detail: 'Detay',
  couldYouGiveUs: 'Size daha iyi hizmet verebilmemiz için bize ulaşmak istediğiniz konuyla ilgili bilgi verebilir misiniz?',
  sendMessageSuccess: 'Mesajınız başarıyla gönderildi.',
  sendCustomerMessageSuccess: 'Talebinizi başarıyla aldık. Ekibimiz en kısa sürede belirttiğiniz konuyla ilgili sizinle iletişime geçecek.',
  teknosaGiftBannerText: 'Teknosacell müşterilerine özel, 20 GB’a varan ücretsiz ve taahhütsüz bulut alan kullanımı hediye.',
  subscribeForFree: 'Ücretsiz Abone Ol',
  languageSelection: 'Dil seçimi',
  language_tr: 'Türkçe',
  language_en: 'English',
  language_nl: 'Dutch',
  name: 'Ad',
  surname: 'Soyad',
  enterName: 'Ad Girin',
  enterSurname: 'Soyad Girin',
  enterYourBirthdate: 'Lütfen doğum tarihinizi girin (MM/DD/YYYY)',
  changeRequest: 'Değişim Talebi',
  yourGSMNumber: 'GSM Numaranız',
  update: 'Güncelle',
  updateYourGSM: 'GSM Numaranızı Güncelleyin',
  updateYourEmail: 'Email Adresinizi Güncelleyin',
  pleaseEnterEmailAddress: 'Lütfen email adresinizi giriniz',
  country: 'Ülke',
  search: 'Arama',
  usageAgreement: 'Kullanım Sözleşmesi',
  searchForTitle: 'Başlık ara',
  allPriorities: 'Tüm Öncelikler',
  dateFilter: 'Tarih Filtresi',
  startDate: 'Başlangıç tarihi',
  endDate: 'Bitiş tarihi',
  enterStartDate: 'Başlangıç tarihi girin (MM/DD/YYYY)',
  enterEndDate: 'Bitiş tarihi girin (MM/DD/YYYY)',
  clear: 'Temizle',
  deletedCreditCardSuccess: 'Kredi Kartı Başarıyla Kaldırıldı!',
  yes: 'Evet',
  pleaseConfirm: 'Lütfen Onaylayın',
  doYouWantToDeleteCreditCard: 'Kredi kartınızı silmek istiyor musunuz?',
  updateCard: 'Kart Güncelle',
  updatedCreditCardSuccess: 'Kredi Kartı Başarıyla Güncellendi!',
  deleteSelected: 'Seçilenleri Sil',
  noSelection: 'İşleme devam edebilmek için seçim yapmanız gerekmektedir.',
  doYouWantToDeleteSelectedCreditCard: 'Seçili kredi kartlarınızı silmek istiyor musunuz?',
  yourPasswordHasBeenChangedSuccessfully: 'Şifreniz başarıyla değiştirildi',
  newPasswordRequired: 'Yeni şifre zorunlu',
  confirmation: 'Onay',
  confirmationCodeIsInvalid: 'Onay kodu geçersiz',
  subscriptionConfirmation: 'Abonelik Onayı',
  freeSubscriptionPackageDefinedForYourUse: 'Seçmiş olduğunuz abonelik paketi ile ücretsiz bulut alanı, kullanımınıza tanımlanacaktır.',
  acceptfreeTermWithUrl: 'Ücretsiz kullanım şart ve koşullarını okudum, onaylıyorum.',
  confirmationCodeSentAgain: 'Onay kodu tekrardan gönderildi.',
  receiveYourCodeSendItAgain: 'Onay kodunuz gelmedi mi? Tekrar gönderin.',
  passwordIsNotValid: 'Parola geçerli değil',
  confirmCodeForEmailDesc: 'Girmiş olduğunuz e-posta adresinize aşağıda bulunan örnekteki gibi bir mail gönderdik. Bu mailde bulunan \'Doğrulama Kodu\' bilgisini, ilgili alana girmeniz gerekmektedir.',
  confirmCodeForPhoneDesc: 'Sisteme kayıtlı telefon numaranıza aşağıda bulunan örnekteki gibi bir sms gönderdik. Bu sms metninde bulunan \'Doğrulama Kodu\' bilgisini, ilgili alana girmeniz gerekmektedir.',
  completeTheProcess: 'İşlemi Tamamla',
  birthDateWithFormat: 'Doğum Tarihi (MM/DD/YYYY)',
  beingRedirectedLoginScreen: 'Giriş ekranına yönlendiriliyorsunuz..',
  goToLogin: 'Girişe Git',
  timeLeft: 'Kalan Süre',
  second: 'Saniye',
  actions: 'İşlemler',
  action: 'İşlem',
  date: 'Tarih',
  description: 'Açıklama',
  unitPrice: 'Birim Fiyat',
  total: 'Toplam',
  paymentStatus: 'Ödeme Durumu',
  errorDetail: 'Hata detayı',
  pleaseLogin: 'Lütfen giriş yapın',
  yourAccountCreated: 'Üyeliğiniz başarılı bir şekilde oluşturuldu. Yönlendiriliyorsunuz...',
  confirmationCodeHasBeenSentPhone: 'Onay kodu telefonunuza gönderildi.',
  yourPhoneConfirmed: 'Telefon numaranız onaylandı.',
  yourPhoneConfirmError: 'Telefon numaranız onaylanırken bir sorun oluştu.',
  confirmationCodeHasBeenSentMail: 'Onay kodu mail adresinize gönderildi.',
  yourMailConfirmed: 'Email adresiniz onaylandı.',
  yourMailConfirmError: 'Email adresiniz onaylanırken bir sorun oluştu.',
  yourRequestHasAlreadyBeenReceived: 'Talebiniz daha önceden alınmış.',
  invalidBirthDate: 'Doğum tarihi alanına geçersiz bir tarih girdiniz.',
  birthDataUpdateError: 'Doğum tarihiniz güncellenirken bir sorun oluştu. İşleminize devam edilemiyor.',
  identityNumberConfirmed: 'TC Kimlik numaranız onaylandı.',
  identityNumberConfirmError: 'TC Kimlik numaranız onaylanırken bir sorun oluştu.',
  male: 'Erkek',
  female: 'Kadın',
  personalAccessTokens: 'Kişisel Erişim Anahtarları',
  personalAccessToken: 'Kişisel Erişim Anahtarı',
  expireDate: 'Son Kullanma Tarihi',
  recordNo: 'Kayıt No',
  doYouWantToDelete: 'Silmek istiyor musunuz?',
  successfullyRemoved: 'Başarıyla kaldırıldı',
  addNewToken: 'Yeni Anahtar Oluştur',
  create: 'Oluştur',
  tokenSuccessResult: 'İşlemlerinizde kullanabileceğiniz anahtar bilgileri aşağıda paylaşılmaktadır. Lütfen bilgileri kaydediniz.',
  teknosaPaymentSuccessMessage: 'İşleminiz başarıyla gerçekleşti. Mail adresinize gelen hesap bilgileriniz ile {0} \'ya giriş yaparak hemen kullanmaya başlayabilirsiniz.',
  contactSalesConsultant: 'Satış Danışmanıyla İletişime Geçin',
  salesConsultant: 'Satış Danışmanı',
  from: 'Kimden',
  to: 'Kime',
  invoiceNo: 'Fatura Numarası',
  isOldInvoice: 'Eski fatura mı?',
  identityIsRequired: 'TC Kimlik numaranız zorunlu',
  birthDateIsRequired: 'Doğum tarihi alanı zorunlu',
  birthDateRequiredFormat: 'Doğum tarihini ay/gün/yıl şeklinde giriniz.',
  newTicket: 'Yeni Talepler',
  newTicketsAvailable: 'Yeni talepler var!',
  serviceListUsed: 'Kullanılan Servis Listesi',
  resourceAmount: 'Kaynak Miktarı',
  monthName1: 'Ocak',
  monthName2: 'Şubat',
  monthName3: 'Mart',
  monthName4: 'Nisan',
  monthName5: 'Mayıs',
  monthName6: 'Haziran',
  monthName7: 'Temmuz',
  monthName8: 'Ağustos',
  monthName9: 'Eylül',
  monthName10: 'Ekim',
  monthName11: 'Kasım',
  monthName12: 'Aralık',
  week: 'Hafta',
  serversAndServices: 'Sunucu ve Servislerim',
  currencyChangeRequestTitle: 'Para birimi değiştirme talebi',
  currencyChangeRequestMessage: 'Sayın yetkili, sistemde kullandığım ana para birimini aşağıdaki gibi değiştirmek istiyorum. Yardımcı olursanız sevinirim.',
  youNeedToLogInToContinue: 'İşleminize devam edebilmeniz için tekrar giriş yapmanız gerekmektedir.',
  present: 'Mevcut',
  destination: 'Hedef',
  copied: 'Kopyalandı!',
  joinTheCampaign: 'Kampanyaya Katıl',
  freeProxyNatDNSService: 'Ücretsiz Proxy-Nat-DNS servisi',
  freeProxyNatDNSServiceInfo: 'Leo One & Leo Star sunucu alın, 1 yıl boyunca Proxy-Nat-DNS servislerini ücretsiz kullanın.',
  freeProxyNatDNSServiceInfoEndDate: '* Kampanya 30.04.2021 tarihine kadar geçerlidir.',
  enterYourCurrency: 'Para biriminizi girin',
  addresses: 'Adreslerim',
  addNewAddress: 'Yeni Adres Ekle',
  updateAddress: 'Adres Güncelle',
  addressName: 'Adres Adı',
  address: 'Adres',
  city: 'Şehir',
  is_invoice_address: 'Fatura adresi mi?',
  doYouWantToDeleteSelectedAddress: 'Seçili adresleri silmek istiyor musunuz?',
  doYouWantToDeleteAddress: 'Silmek istediğinize emin misiniz?',
  save: 'Kaydet',
  deletedAddressSuccess: 'Adres başarıyla silindi.',
  addressLine1: 'Adres Satır 1',
  addressLine2: 'Adres Satır 2',
  state: 'İlçe',
  postCode: 'Posta Kodu',
  addedAddressSuccess: 'Adres başarıyla eklendi.',
  updatedAddressSuccess: 'Adres başarıyla düzenlendi.',
  vat: 'KDV',
  notRegisteredAddress: 'Kayıtlı bir fatura adresiniz yok. Lütfen listeden bir adres seçin.',
  notRegisteredAddressAddPlease: 'Kayıtlı adresiniz yok. Lütfen yeni bir adres ekleyin.',
  closeTicket: 'Destek Talebini Kapat',
  showSupportDetail: 'Destek Detayını Gör',
  searchForSupport: 'Destek talebi ara',
  goToPage: 'Sayfaya git',
  totalRecord: 'Toplam Kayıt',
  filters: 'Filtreler',
  searchForTicketNumber: 'Talep numarasını ara',
  myAccount: 'Hesabım',
  login: 'Giriş Yap',
  register: 'Kayıt Ol',
  callback: 'Callback',
  pageNotFound: 'Sayfa Bulunamadı',
  selectPhoneCode: 'Telefon kodu seçiniz',
  giftPriceTitle: 'Sosyal hesaplarınızla giriş yapın ve<br /> <b>$100\'ınızı kullanın</b>',
  giftPrice: '$100 HEDİYE',
  giftPriceInfo: 'Tüm işlemlerde kullanabileceğiniz $100 hediyeniz kayıt olduktan sonra hesabınıza aktarılacaktır.',
  or: 'veya',
  loginWith: '{0} ile giriş yap',
  notTurkishCitizen: 'Türk vatandaşı değilim.',
  teamManagement: 'Takım Yönetimi',
  teams: 'Takımlar',
  teamName: 'Takım Adı',
  doYouWantToDeleteSelectedTeams: 'Seçili Takımlarınızı silmek istiyor musunuz?',
  deletedTeamSuccess: 'Takım Başarıyla Kaldırıldı!',
  createNewTeam: 'Yeni Takım Oluştur',
  addedTeamSuccess: 'Takım başarıyla eklendi.',
  updatedTeamSuccess: 'Takım başarıyla düzenlendi.',
  teamManagementDetail: 'Takım Yönetimi Detayı',
  members: 'Üyeler',
  addNewMembers: 'Yeni Üyeler Ekle',
  doYouWantToDeleteSelectedMembers: 'Seçili Üyeleri silmek istiyor musunuz?',
  deletedMemberSuccess: 'Üye Başarıyla Kaldırıldı!',
  birthDateRequiredFormatPlaceholder: 'ay/gün/yıl',
}