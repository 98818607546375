// action types
export const PROD_ENV = 'prodEnv'

// mutation types
export const SET_PROD_ENV = 'setProdEnv'

const state = {
  prodEnv: null,
}

const getters = {
  prodEnv(state) {
    return state.prodEnv
  },
}

const actions = {
  [PROD_ENV](context, payload) {
    context.commit(SET_PROD_ENV, payload)
  },
}

const mutations = {
  [SET_PROD_ENV](state, payload) {
    state.prodEnv = payload
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
