import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'
import { GET_MY_USER } from '@/core/services/store/auth.module'
import store from '@/core/services/store'

Vue.use(MLInstaller)

import en from '@/lang/en.js'
import tr from '@/lang/tr.js'
import nl from '@/lang/nl.js'


const MLService = {
  init() {
    let currentLang = window.localStorage.getItem('currentLanguage')
    if (currentLang === null || currentLang === '') {
      currentLang = getEnvLocale()//getNavigatorLocale()
    }
    new MLCreate({
      initial: currentLang,
      save: process.env.NODE_ENV === 'production',
      languages: [
        new MLanguage('tr').create(tr),

        new MLanguage('en').create(en),

        new MLanguage('nl').create(nl),
      ],
      middleware: (component, path) => {
        // you can mutate path here
        // you should return path updated
        return path
      },
    })
  },
  setFirstLanguage() {
    return new Promise(function (resolve, reject) {
      //Eğer daha önceden browser dolmuş ise, kullanıcı diline göre getirsin diye kontrol edip, değeri silme işlemi yapıyorum
      let currentBrowserLanguage = window.localStorage.getItem('currentBrowserLanguage')
      if (currentBrowserLanguage !== null) {
        window.localStorage.removeItem('currentBrowserLanguage')
        window.localStorage.removeItem('currentLanguage')
      }

      let lang = ''
      let currentLanguage = window.localStorage.getItem('currentLanguage')
      if (currentLanguage !== null && currentLanguage !== '') {
        MLService.init()
        resolve()
      }
      else {
        store.dispatch(GET_MY_USER).then((res) => {
          if (typeof (res) !== "undefined" && typeof (res.data) !== "undefined") {
            if (res.data.language !== "") {
              lang = res.data.language.toLowerCase()
            }
            else if (res.data.default_locale !== "") {
              lang = res.data.default_locale.toLowerCase()
            }
          }

          lang = controlAndGetLocale(lang)

          window.localStorage.setItem('currentLanguage', lang)
          MLService.init()
          resolve()
        }).catch((error) => {
          lang = controlAndGetLocale(lang)
          window.localStorage.setItem('currentLanguage', lang)
          MLService.init()
          resolve()
        })
      }
    })
  },
  //Auth, callback, 404 gibi sayfalarda kullanıcı giriş yapmamış olabilir. Buralarda sadece tarayıcı dili yeterli.
  setBrowserLanguage() {
    return new Promise(function (resolve, reject) {

      window.localStorage.removeItem('vueml-lang')
      let currentLanguage = window.localStorage.getItem('currentLanguage')
      let currentBrowserLanguage = window.localStorage.getItem('currentBrowserLanguage')
      if (currentLanguage !== null && currentLanguage !== '') {
        MLService.init()
        resolve()
      }
      else if (currentBrowserLanguage !== null && currentBrowserLanguage !== '') {
        window.localStorage.setItem('currentLanguage', currentBrowserLanguage)
        MLService.init()
        resolve()
      }
      else {
        let lang = getEnvLocale()//getNavigatorLocale()
        window.localStorage.setItem('currentBrowserLanguage', lang)
        window.localStorage.setItem('currentLanguage', lang)
        MLService.init()
        resolve()
      }
    })
  }
}

function getNavigatorLocale() {
  let navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language
  return navigatorLocale === 'tr' ||
    navigatorLocale === 'en' ||
    navigatorLocale === 'nl'
    ? navigatorLocale
    : 'en'
}

function getEnvLocale() {
  let l = getLocaleInfo()
  if (l == null || l == '')
    return 'en'

  return l
}

function controlAndGetLocale(lang) {
  if (lang === '') {
    lang = getEnvLocale()//getNavigatorLocale()
  }
  if (lang !== 'tr' && lang !== 'en' && lang !== 'nl') {
    lang = 'en'
  }
  return lang
}

//Varsa ?locale parametresi yoksa default language dönüyor.
function getLocaleInfo() {
  let url = new URL(window.location.href)
  let _locale = url.searchParams.get('locale')
  if (_locale == 'tr' || _locale == 'en' || _locale == 'nl')
    return _locale

  return process.env.VUE_APP_DEFAULT_LANGUAGE

}

export default MLService