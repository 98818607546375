import JwtService from '@/core/services/jwt.service'

// action types
export const SKIP_WELCOME = 'skipWelcome'

// mutation types
export const SET_SKIP_WELCOME = 'setSkipWelcome'

const state = {
  skipWelcome: !!JwtService.getSkipWelcome() || false,
}

const getters = {
  skipWelcome(state) {
    return state.skipWelcome
  },
}

const actions = {
  [SKIP_WELCOME](context, payload) {
    context.commit(SET_SKIP_WELCOME, payload)
  },
}

const mutations = {
  [SET_SKIP_WELCOME](state, payload) {
    state.skipWelcome = payload
    JwtService.saveSkipWelcome(payload)
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
