import Vue from 'vue'
import store from '@/core/services/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/core/services/jwt.service'
import EventBus from '@/event-bus'
let emitLogoutOperation = false

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      'Authorization'
    ] = `${JwtService.getToken()}`
  },

  /**
   * Remove the default HTTP request headers
   */
  removeHeader() {
    delete Vue.axios.defaults.headers.common["Authorization"];
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw error
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    return Vue.axios.get(resource).catch(error => {
      // console.log(error);
      if (error && error.response && error.response.status == 404) {
        console.clear()
      }
      this.controlLogout(error, resource)
      throw error
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    let t = Vue.axios.defaults.headers;
    return Vue.axios.post(`${resource}`, params).catch(error => {

      this.controlLogout(error, resource)

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        (error.response.data.error.code == 'ERROR-UNPROCESSABLE-ENTITIY'
          ||
          error.response.data.error.code == 'ERROR-BAD-REQUEST')
      ) {
        let errors =
          error.response.data && error.response.data.error
            ? (typeof (error.response.data.error.errors) !== "undefined" ? error.response.data.error.errors :
              (typeof (error.response.data.error.message) !== "undefined" ? error.response.data.error.message.split() : {}))
            : {}

        EventBus.$emit('errors', errors)

        throw errors ? errors : 'Error'
      }

      throw 'Error'
    })
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params).catch(error => {
      this.controlLogout(error, resource)
      // console.log(error);
      throw error
    })
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params).catch(error => {

      this.controlLogout(error, resource)

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        (error.response.data.error.code == 'ERROR-UNPROCESSABLE-ENTITIY'
          ||
          error.response.data.error.code == 'ERROR-BAD-REQUEST')
      ) {
        let errors =
          error.response.data && error.response.data.error
            ? (typeof (error.response.data.error.errors) !== "undefined" ? error.response.data.error.errors :
              (typeof (error.response.data.error.message) !== "undefined" ? error.response.data.error.message.split() : {}))
            : {}

        EventBus.$emit('errors', errors)

        throw errors ? errors : 'Error'
      }

      throw 'Error'
    })
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      this.controlLogout(error, resource)
      // console.log(error);
      throw error
    })
  },
  controlLogout(error, payload) {
    //oauth/token/delete -> callback sayfasında sonsuz döngüye sokabiliyor.
    if (
      payload &&
      //logout
      !payload.includes('oauth/token/delete') &&
      //password-reset
      !payload.includes('validate-password-reset') &&
      !payload.includes('reset-password') &&
      //register
      !payload.includes('user-verification') &&
      !payload.includes('register') &&
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.status == 401) {
      if (
        emitLogoutOperation == false) {
        emitLogoutOperation = true

        this.get(`am-i-logged-in`)
          .then(({ data }) => {
            if (data && data.data && data.data.is_ok == true) {
              //do nothing!
            }
            else {
              EventBus.$emit('logout')
            }
          })
          .catch(err => {
            EventBus.$emit('logout')
          })
      }
    }
    else {
      emitLogoutOperation = false
    }
  }
}

export default ApiService
