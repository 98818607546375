import matomoScript from '@/assets/js/matomo'
import JwtService from '@/core/services/jwt.service'
import { SET_VISITOR_ID } from '@/core/services/store/auth.module'


export default function matomoUser({ next, store }) {

  const visitorId = JwtService.getVisitorId()
  if (visitorId) {
    matomoScript.run(visitorId)
  }
  else {
    store
      .dispatch(SET_VISITOR_ID)
      .then(res => {
        matomoScript.run(res.data.unique_visitor_id)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return next()
}