import ApiService from '@/core/services/api.service'

// action types
export const GET_CREDIT_CARDS = 'getCreditCards'

// mutation types
export const SET_CREDIT_CARDS = 'setCreditCards'

const state = {
  creditCards: [],
}

const getters = {
  creditCards(state) {
    return state.creditCards
  },
}

const actions = {
  [GET_CREDIT_CARDS](context) {
    return new Promise((resolve, reject) => {
      ApiService.get('credit-cards')
        .then(({ data }) => {
          context.commit(SET_CREDIT_CARDS, data.data)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {
  [SET_CREDIT_CARDS](state, payload) {
    state.creditCards = payload
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
