import ApiService from '@/core/services/api.service'
import JwtService from '@/core/services/jwt.service'

// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const LOGOUT_FORCE = 'logoutForce'
export const REGISTER = 'register'
export const UPDATE_USER = 'updateUser'
export const GET_MY_USER = 'getMyUser'
export const VERIFY_REGISTERED_USER = 'verifyRegisteredUser'
export const IS_LOGGED_USER = 'isLoggedUser'
export const SET_VISITOR_ID = 'setVisitorId'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setUser'
export const SET_ERROR = 'setError'
export const SET_MY_USER = 'setMyUser'
export const SET_REGISTERED_USER = 'setRegisteredUser'
export const SET_VERIFY_REGISTERED_USER = 'setVerifyRegisteredUser'

const state = {
  errors: null,
  user: {},
  myUser: {},
  registeredUser: {},
  userVerification: {},
  isAuthenticated: !!JwtService.getToken(),
}

const getters = {
  currentUser(state) {
    return state.user
  },
  myUser(state) {
    return state.myUser
  },
  registeredUser(state) {
    return state.registeredUser
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
  userVerification(state) {
    return state.userVerification
  },
}

const actions = {
  [LOGIN](context, payload) {
    context.commit(SET_AUTH, payload.data)
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`${process.env.VUE_APP_LOGIN_URL}v2/oauth/token/delete`)
        .then(() => {
          context.commit(PURGE_AUTH)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  [LOGOUT_FORCE](context) {
    context.commit(PURGE_AUTH)
  },
  [REGISTER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('register', {
        ...payload,
      })
        .then(({ data }) => {
          context.commit(SET_REGISTERED_USER, data.data)
          resolve(data)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  },
  [VERIFY_REGISTERED_USER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`user-verification?email=${payload}`)
        .then(({ data }) => {
          context.commit(SET_VERIFY_REGISTERED_USER, data)
          resolve(data)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  },
  [VERIFY_AUTH](context) {
    if (
      (JwtService.getToken() && JwtService.getCurrentUser()) ||
      JwtService.getUserRegistered()
    ) {
      ApiService.setHeader()
      context.commit(SET_AUTH, JwtService.getCurrentUser())

      if (JwtService.getUserVerification()) {
        context.commit(
          SET_VERIFY_REGISTERED_USER,
          JwtService.getUserVerification()
        )
      }

      if (JwtService.getUserRegistered()) {
        context.commit(SET_REGISTERED_USER, JwtService.getUserRegistered())
      }
    } else {
      context.commit(PURGE_AUTH)
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload
    const user = { email, username, bio, image }
    if (password) {
      user.password = password
    }

    return ApiService.put('user', user).then(({ data }) => {
      context.commit(SET_AUTH, data)
      return data
    })
  },
  [GET_MY_USER](context) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        'users/me?include=masterAccount.type,currentAccount,roles,representedAccounts,country&fields=gender,birthday,cell_phone_code,cell_phone,is_verified,language'
      )
        .then(({ data }) => {
          context.commit(SET_MY_USER, data.data)
          resolve(data)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  },
  [IS_LOGGED_USER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`am-i-logged-in`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  [SET_VISITOR_ID](context) {
    let domainId = process.env.VUE_APP_DOMAIN_ID
      ? process.env.VUE_APP_DOMAIN_ID
      : 'DQLBbboqLw'
    return new Promise((resolve, reject) => {
      ApiService.post(
        `${process.env.VUE_APP_MAAPI_URL}web/${domainId}/visitors`
      )
        .then(({ data }) => {
          JwtService.setVisitorId(data.data.unique_visitor_id)
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_AUTH](state, payload) {
    state.isAuthenticated = true
    state.user = payload ? payload.account : {}
    state.errors = {}
    JwtService.saveToken(payload)
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    JwtService.destroyToken()
    JwtService.destroyUserVerification()
    JwtService.destroyUserRegistered()
    JwtService.destroySkipWelcome()
  },
  [SET_MY_USER](state, payload) {
    state.myUser = payload
  },
  [SET_REGISTERED_USER](state, payload) {
    state.registeredUser = payload
    JwtService.saveUserRegistered(payload)
  },
  [SET_VERIFY_REGISTERED_USER](state, payload) {
    state.userVerification = { ...payload }
    JwtService.saveUserVerification({ ...payload })
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
