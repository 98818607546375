export default function generateToast(app, variant, text, addErrorTitle = false) {

  if (addErrorTitle) {
    const h = this.$createElement
    const vNodesMsg = [h(
      'p',
      [
        this.$ml.get('anErrorHasOccurred')
      ]
    ),
    h(
      'p',
      [
        h('strong', `${this.$ml.get('errorDetail')} : `),
        `${text}`,
      ]
    )]

    text = vNodesMsg
  }

  app.$bvToast.toast(text, {
    title: `${variant == 'success' ? app.$ml.get('success') : app.$ml.get('error')
      }`,
    variant: variant,
    solid: true,
  })
}
