<template>
  <Datepicker class="pc-datetime form-control"
              :placeholder="$ml.get(placeholder ? placeholder : label)"
              :name="label"
              v-model="model"
              @input="input"
              :format="showFormat ? showFormat : format"
              :language="languages[langVal]"
              :state="state != undefined ? state : null">
  </Datepicker>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import * as lang from 'vuejs-datepicker/dist/locale'
import moment from 'moment'

export default {
  name: 'DateTime',
  props: {
    value: String | Date,
    valueFormat: String,
    showFormat: String,
    label: String,
    placeholder: String,
    state: {
      type: Boolean,
      default: undefined,
    },
    langVal: {
      type: String,
      default: 'en',
    },
    defaultValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: '',
      languages: lang,
    }
  },
  created() {
    this.setValue()
  },
  computed: {
    format() {
      if (this.langVal == 'tr') return 'dd/MM/yyyy'
      else if (this.langVal == 'en')
        return moment(this.model).format('MM/DD/YYYY') // Custom format for EN lang
    },
  },
  methods: {
    setValue() {
      this.model = this.defaultValue != '' ? this.defaultValue : ''
    },
    input() {
      this.$emit('input', moment(this.model).format(this.valueFormat))
    },
  },
  watch: {
    defaultValue() {
      this.setValue()
    },
    value(newVal) {
      this.model = newVal
    },
  },
  components: {
    Datepicker,
  },
}
</script>

<style lang="scss">
.pc-datetime {
  input {
    width: 100%;
    outline: 0;
  }
  .vdp-datepicker__calendar .cell.selected {
    color: #fff;
    background: #00baf2;
  }
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid #00baf2;
  }
}
</style>