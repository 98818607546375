import { IS_LOGGED_USER, LOGOUT_FORCE } from '@/core/services/store/auth.module'
import JwtService from '@/core/services/jwt.service'

export default function verifyLoggedUser({ next, store, router }) {
  const currentUser = JwtService.getCurrentUser()

  if (currentUser) {
    store
      .dispatch(IS_LOGGED_USER)
      .then(res => {
        if (res && res.data && res.data.is_ok) {
          //console.log('Success!!')
        }
        else {
          store.dispatch(LOGOUT_FORCE)
          router.push({ name: 'login' })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return next()
}