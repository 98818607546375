<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-pro/css/all.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';

// Main demo style scss
@import 'assets/sass/style.vue';

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import EventBus from '@/event-bus'
import { OVERRIDE_LAYOUT_CONFIG } from '@/core/services/store/config.module'
import JwtService from '@/core/services/jwt.service'

export default {
  name: 'MetronicVue',
  data() {
    return {
      titleKey: '',
    }
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG)

    //Api Service Error Handler
    EventBus.$on('errors', (payload) => {
      if (payload) {
        Object.keys(payload).forEach((item) => {
          this.$generateToast(this, 'danger', payload[item], true)
        })
      }
    })

    EventBus.$on('logout', () => {
      if (
        this.$route.name !== 'register' &&
        this.$route.name !== 'passwordReset' &&
        this.$route.name !== 'callback'
      ) {
        this.$generateToast(
          this,
          'danger',
          this.$ml.get('youNeedToLogInToContinue')
        )
        setTimeout(() => {
          JwtService.destroyToken()
          this.$router.push({ name: 'login' })
        }, 2000)
      }
    })
    EventBus.$on('changeLanguage', (payload) => {
      this.$ml.change(payload)
      this.setTitle(this.titleKey)
    })
  },
  methods: {
    setTitle(titleKey) {
      document.title = titleKey
        ? this.$ml.get(titleKey) + ' - ' + this.$ml.get('myAccount')
        : this.$ml.get('myAccount')
    },
  },
  watch: {
    $route(to) {
      // Change page title before each page load
      this.setTitle(to.meta.titleKey ? to.meta.titleKey : null)
      this.titleKey = to.meta.titleKey
    },
  },
}
</script>
