
const matomoScript = {
  run(visitorId) {
    // console.log("run.VisitorId ", visitorId)

    // Matomo
    var _paq = (window._paq = window._paq || [])
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
    _paq.push(["setCookieDomain", "*.plusclouds.com"]);
    _paq.push(["setDomains", ["*.plusclouds.com", "*.accounts.plusclouds.com", "*.blitz.plusclouds.com", "*.plusclouds.com.tr", "*.stash.plusclouds.com"]]);
    _paq.push(["enableCrossDomainLinking"]);
    _paq.push(['setUserId', visitorId])
    _paq.push(['trackPageView'])
    _paq.push(['enableLinkTracking'])
      ; (function () {
        var u = '/'
        _paq.push(['setTrackerUrl', u + 's.php'])
        _paq.push(['setSiteId', '5'])
        var d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0]
        g.type = 'text/javascript'
        g.async = true
        g.src = u + 's.js'
        s.parentNode.insertBefore(g, s)
      })()
    // End Matomo Code 
  }
}
export default matomoScript
