import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.module'
import creditCards from './creditCards.module'
import htmlClass from './htmlclass.module'
import config from './config.module'
import breadcrumbs from './breadcrumbs.module'
import customsubheader from "./customsubheader.module";
import welcomePage from './welcomePage.module'
import envProd from './envProd.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    creditCards,
    htmlClass,
    config,
    breadcrumbs,
    customsubheader,
    welcomePage,
    envProd,
  },
})
