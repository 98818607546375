import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/core/services/store'
import ApiService from '@/core/services/api.service'

Vue.config.productionTip = false

// Global 3rd party plugins
import 'popper.js'
import 'tooltip.js'
import PerfectScrollbar from 'perfect-scrollbar'
window.PerfectScrollbar = PerfectScrollbar
import ClipboardJS from 'clipboard'
window.ClipboardJS = ClipboardJS

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n'
import vuetify from '@/core/plugins/vuetify'
import '@/core/plugins/portal-vue'
import '@/core/plugins/bootstrap-vue'
import '@/core/plugins/perfect-scrollbar'
import '@/core/plugins/highlight-js'
import '@/core/plugins/inline-svg'
import '@/core/plugins/apexcharts'
import '@/core/plugins/metronic'
import '@mdi/font/css/materialdesignicons.css'
// Global Component Registration
import '@/core/plugins/globalRegistration'

//Import Vue The Mask Globally Directive
import { mask } from 'vue-the-mask'
Vue.directive('mask', mask)

// Import Vue Card Format Globally Plugin
import VueCardFormat from 'vue-credit-card-validation'
Vue.use(VueCardFormat)

import spinnerLoader from '@/core/plugins/spinnerLoader.js'
Vue.prototype.$spinnerLoader = spinnerLoader

import customFunctions from '@/core/plugins/customFunctions.js'
Vue.prototype.$customFunctions = customFunctions

import generateToast from '@/core/plugins/generateToast.js'
Vue.prototype.$generateToast = generateToast

import urlify from '@/core/plugins/urlify.js'
Vue.prototype.$urlify = urlify

// API service init
ApiService.init()

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
