export default {
  hello: 'Hi',
  welcomeToPlusClouds: 'Welcome to PlusClouds',
  welcomeToPlusGrowth: 'Welcome to PlusGrowth',
  welcomeText: 'Welcome',
  welcomeContentPlusClouds:
    'Welcome to the world of PlusClouds, we are really glad that you are here with us. We see that you are interested in cloud technologies. If you want to have cloud solutions to meet your needs, contact us, and we will create the most suitable ways for you in the discovery you will start in cloud solutions.',
  welcomeContentPlusGrowth:
    'Welcome to the world of PlusGrowth, we are really glad that you are here with us. We see that you are interested in cloud technologies. If you want to have cloud solutions to meet your needs, contact us, and we will create the most suitable ways for you in the discovery you will start in cloud solutions.',
  hereIsOurNumber: 'Here is the number you can reach us:',
  niceWorkingDay: 'Have a nice working day,',
  sincerely: 'Yours sincerely.',
  welcomeExperienceText: 'We need some approvals to improve your experience.',
  selectLanguage: 'Select Language',
  mailVerification: 'Mail Verification',
  gsmVerification: 'Gsm Verification',
  identityVerification: 'Identity Verification',
  welcomePermissionText:
    'If you wish, our support team can contact you by passing these steps. let it pass and help.',
  skipSteps: 'Skip Steps',
  customerRepresentative: 'Your Customer Representative',
  verifyYourEmailAddress: 'Verify your mail address',
  confirmEmailInfoText:
    'Please activate your mail by entering the code we send to your email address.',
  confirmEmailConfirmationCode: "Didn't the confirmation code arrive?",
  tryAgain: 'Try Again',
  confirm: 'Confirm',
  confirmationCode: 'Confirmation code',
  sendConfirmationCode: 'Send confirmation code',
  saveAndSendConfirmationCode: 'Save and send confirmation code',
  verificationYourGSM: 'Verify your GSM',
  pleaseEnterGSMNo: 'Please enter your GSM Number',
  verifyYourIdentityNumber: 'Verify your Identity Number',
  verifyYourIdentityNumberInfoText:
    'Please Confirm your account by entering your Identity number',
  letUsCallYou: 'Let Us Call You',
  letUsCallYouInfoText:
    'We see you wanted to go through the steps. No problem. If you leave your phone number, our support team will contact you.',
  chooseDay: 'Choose Day',
  chooseTime: 'Choose Time',
  success: 'Success',
  successful: 'Successful!',
  error: 'Error',
  letUsCallYouSuccessText:
    'We got your request. Our support team will contact you as soon as possible and provide the necessary support.',
  userProfile: 'User Profile',
  signOut: 'Sign out',
  general: 'General',
  payment: 'Payment',
  creditCard: 'Credit Card',
  creditCards: 'Credit Cards',
  noCreditCardInfo:
    'You have not shared about your bank credit card details with us yet!',
  addPaymentMethod: 'Add Payment Method',
  addNewCard: 'Add New Card',
  cardType: 'TYPE',
  cardName: 'CARD NAME',
  ending: 'ENDING',
  isValid: 'IS VALID',
  isDefault: 'IS DEFAULT',
  default: 'Default',
  no: 'No',
  valid: 'Valid',
  invalid: 'Invalid',
  verifyCreditCard: 'Please verify your credit card.',
  noDataAvailable: 'No data available',
  verifyCreditCardTitle: 'Account Verification',
  verifyCreditCardSubTitle: 'To verify that the card belongs to you, enter the value between 0-1 TL drawn from your card in the field below. (The amount withdrawn will be credited to your account.)',
  verifyCreditCardSecondSubTitle:'Verification should be done within 1 minute. If your transaction exceeds this period, you must delete your card and re-identify it.',
  amount: 'Amount',
  verify: 'Verify',
  invalidAmount: 'Invalid Amount.',
  verifyCreditCardSuccess: 'Verify your credit card success!',
  anErrorHasOccurred:
    'An error has occurred, please try again later or contact your administrator.!',
  cardHolderName: 'Card Holder Name',
  cardNumber: 'Card Number',
  cardInformation: 'Card Information',
  expDate: 'Exp Date',
  cvv: 'CVV',
  addCard: 'Add Card',
  invalidCreditCardHolderName: 'Invalid Credit Card Holder Name',
  invalidCreditCardNumber: 'Invalid Credit Card Number',
  invalidExpirationDate: 'Invalid Expiration Date',
  invalidCVCNumber: 'Invalid CVC Number',
  addedCreditCardSuccess: 'Your Credit Card Has Been Added Successfully! You must complete the verification process within 1 minute.',
  billingHistory: 'Billing History',
  pay: 'Pay',
  payNow: 'Pay Now',
  paid: 'Paid',
  payInvoice: 'Pay Invoice',
  selectedInvoiceAmount: 'Selected invoice amount',
  pleaseSelectYourCreditCard: 'Please select your credit card',
  yourBillingPaymentIsSuccessful: 'Your billing payment is successful!',
  memberSince: 'Member since',
  accountType: 'Account Type',
  yourAccountType: 'Your account type',
  updateAccountTypeText: 'Please select or update your account type',
  pleaseSelectAccountType: 'Please select account type',
  saveChanges: 'Save Changes',
  generalSettings: 'General Settings',
  basic: 'Basic',
  nameSurname: 'Name Surname',
  enterNameSurname: 'Enter name surname',
  birthdate: 'Birthdate',
  identityNumber: 'Identity Number',
  okey: 'OK',
  password: 'Password',
  enterYourCurrentPassword: 'Enter your current password',
  emailAddress: 'Email address',
  enterEmailAddress: 'Enter email address',
  newPassword: 'New Password',
  enterYourNewPassword: 'Enter your new password',
  newPasswordAgain: 'New Password Again',
  enterYourNewPasswordAgain: 'Enter your new password again',
  gender: 'Gender',
  pleaseSelectGender: 'Please select gender',
  birthDate: 'Birth Date',
  preferences: 'Preferences',
  language: 'Language',
  pleaseSelect: 'Please select',
  currency: 'Currency',
  updatedGeneralSettingsSuccess: 'Updated General Settings Success!',
  newHere: 'New Here?',
  createAnAccount: 'Create an Account',
  loginWithPlusClouds: 'Login with PlusClouds',
  loginWithPlusGrowth: 'Login with PlusGrowth',
  pleaseWaitingRedirectToBack: 'Please wait, redirecting back to Leo Console.',
  alreadyHaveAnAccount: 'Already have an account?',
  signIn: 'Sign In',
  fullname: 'Fullname',
  fullnameIsRequired: 'Fullname is required',
  emailIsRequiredInfoText: 'Email is required and a valid email address.',
  passwordIsRequired: 'Password is required.',
  passwordConfirmation: 'Password Confirmation',
  passwordsMustBeIdentical: 'Passwords must be identical.',
  createFreeAccount: 'Create Free Account',
  score: 'Score',
  fair: 'Fair',
  good: 'Good',
  veryStrong: 'Very strong',
  eightCharactersMinimum: '8 characters minimum',
  oneNumber: 'One number',
  oneSpecialCharacter: 'One special character',
  oneUpper: '1 uppercase letter',
  oneLower: '1 lowercase letter',
  passwordReset: 'Password Reset',
  didYouComeWrong: 'Did you come wrong?',
  confirmCodeForEmail: 'Confirmation code received in your e-mail',
  confirmCodeForPhone: 'Confirmation code you received on your phone',
  confirmCodeForEmailRequired:
    'Confirmation code sent to your e-mail address is required',
  confirmCodeForPhoneRequired:
    'Confirmation code received on your phone is required',
  phoneIsRequired: 'Phone field is required',
  phoneCodeIsRequired: 'Phone code field is required',
  confirmCodeForEmailIsAccepted: 'Confirmation code sent to your e-mail has been accepted',
  confirmCodeForPhoneIsAccepted: 'Confirmation code sent to your phone has been accepted',
  captcha: 'Captcha',
  subscribe: 'Subscribe',
  subscription: 'Subscription',
  piece: 'Piece',
  infrastructureResource: 'Infrastructure Resource',
  registered: 'Registered',
  unregistered: 'Unregistered',
  packets: 'Packets',
  monthlyPackagesWithandWithoutCommitment: 'Monthly packages with and without commitment',
  monthly: 'Monthly',
  discount: 'Discount',
  free: 'Free',
  cancel: 'Cancel',
  youMustAcceptTerm: 'You must accept the terms of use.',
  acceptTermWithUrl: 'I have read and accept terms of use.',
  confirmOfPurchase: 'Confirm of purchase',
  yourPaymentOptions: 'Your payment options',
  yourPackage: 'Your Package',
  youCanMakePurchaseByChoosingCards: 'You can make your purchase by choosing one of the cards below.',
  freeCloudService: 'Free Cloud Service',
  teknosaBannerSubtitle: 'Store all the files on your mobile device and computer securely in the cloud, which is free of charge, exclusive to Teknosacell customers.',
  youMustHaveLeastOneCreditCard: 'In order to make a transaction, you must have at least one credit card defined.',
  allTickets: 'All Tickets',
  open: 'Open',
  pending: 'Pending',
  closed: 'Closed',
  searchForPerson: 'Search for person',
  allCategories: 'All Categories',
  category: 'Category',
  selectCategory: 'Select Category',
  myTickets: 'My Tickets',
  openTicket: 'Open Ticket',
  ticketNumber: 'Ticket Number',
  latestActivityOnYourAccountIsDisplayed: 'The latest activity history on your account is displayed below!',
  priority: 'Priority',
  title: 'Title',
  createdDate: 'Created Date',
  status: 'Status',
  support: 'Support',
  subject: 'Subject',
  priority: 'Priority',
  selectPriority: 'Select priority',
  message: 'Message',
  attachment: 'Attachment',
  chooseFile: 'Choose file..',
  dropFileHere: 'Drop file here..',
  low: 'LOW',
  medium: 'MEDIUM',
  high: 'HIGH',
  addedTicketSuccess: 'Added Ticket Success!',
  addedAnswerSuccess: 'Added Answer Success!',
  writeSomething: 'Write something...',
  send: 'Send',
  writeReplyOrDragFilesHere: 'Write your reply or drag files here',
  getFreeStash: 'Get Free Stash',
  contactMe: 'CONTACT ME',
  canWeHelpYou: 'How can we help you?',
  contactUs: 'Contact Us',
  detail: 'Detail',
  couldYouGiveUs: 'Could you give us information about the subject you want to reach us so that we can serve you better?',
  sendMessageSuccess: 'Your message has been sent successfully.',
  sendCustomerMessageSuccess: 'We have received your request successfully. Our team will contact you as soon as possible on the subject you specified.',
  teknosaGiftBannerText: 'Up to 20 GB of cloud space free of charge and without commitment, exclusive to Teknosacell customers.',
  subscribeForFree: 'Subscribe For Free',
  languageSelection: 'Language Selection',
  language_tr: 'Türkçe',
  language_en: 'English',
  language_nl: 'Dutch',
  name: 'Name',
  surname: 'Surname',
  enterName: 'Enter Name',
  enterSurname: 'Enter Surname',
  enterYourBirthdate: 'Please enter your birthdate (MM/DD/YYYY)',
  changeRequest: 'Change Request',
  yourGSMNumber: 'Your GSM Number',
  update: 'Update',
  updateYourGSM: 'Update Your GSM',
  updateYourEmail: 'Update Your Email Address',
  pleaseEnterEmailAddress: 'Please enter your email address',
  country: 'Country',
  search: 'Search',
  usageAgreement: 'Usage Agreement',
  searchForTitle: 'Search for title',
  allPriorities: 'All Priorities',
  dateFilter: 'Date Filter',
  startDate: 'Start date',
  endDate: 'End date',
  enterStartDate: 'Enter start date (MM/DD/YYYY)',
  enterEndDate: 'Enter end date (MM/DD/YYYY)',
  clear: 'Clear',
  deletedCreditCardSuccess: 'Credit Card Successfully Removed!',
  yes: 'Yes',
  pleaseConfirm: 'Please Confirm',
  doYouWantToDeleteCreditCard: 'Do you want to delete your credit card?',
  updateCard: 'Update Card',
  updatedCreditCardSuccess: 'Credit Card Successfully Updated!',
  deleteSelected: 'Delete Selected',
  noSelection: 'You have to make a selection to continue the operation.',
  doYouWantToDeleteSelectedCreditCard: 'Do you want to delete your selected credit cards?',
  yourPasswordHasBeenChangedSuccessfully: 'Your password has been changed successfully',
  newPasswordRequired: 'New password is required',
  confirmation: 'Confirmation',
  confirmationCodeIsInvalid: 'Confirmation code is invalid',
  subscriptionConfirmation: 'Subscription Confirmation',
  freeSubscriptionPackageDefinedForYourUse: 'With the subscription package you have chosen, free cloud space will be defined for your use.',
  acceptfreeTermWithUrl: 'I have read the free terms and conditions of use, I approve',
  confirmationCodeSentAgain: 'Confirmation code has been sent again.',
  receiveYourCodeSendItAgain: 'Didn\'t receive your confirmation code? Send again.',
  passwordIsNotValid: 'Password is not valid',
  confirmCodeForEmailDesc: 'We have sent an e-mail to your e-mail address as in the example below. You must enter the \'Verification Code\' information in this e-mail in the relevant field. ',
  confirmCodeForPhoneDesc: 'We have sent an SMS to your registered phone number as in the example below. You need to enter the \'Verification Code\' information in this SMS text in the relevant field.',
  completeTheProcess: 'Complete The Process',
  birthDateWithFormat: 'Birth Date (MM/DD/YYYY)',
  beingRedirectedLoginScreen: 'You\'re being redirected to the login screen..',
  goToLogin: 'Go to Login',
  timeLeft: 'Time Left',
  second: 'Second',
  actions: 'Actions',
  action: 'Action',
  date: 'Date',
  description: 'Description',
  unitPrice: 'Unit Price',
  total: 'Total',
  paymentStatus: 'Payment Status',
  errorDetail: 'Error detail',
  pleaseLogin: 'Please login',
  yourAccountCreated: 'Your account has been successfully created. You are being redirected...',
  confirmationCodeHasBeenSentPhone: 'Confirmation code has been sent to your phone',
  yourPhoneConfirmed: 'Your phone number has been confirmed.',
  yourPhoneConfirmError: 'There was a problem verifying your phone number.',
  confirmationCodeHasBeenSentMail: 'Confirmation code has been sent to your email address',
  yourMailConfirmed: 'Your email address has been confirmed.',
  yourMailConfirmError: 'There was a problem verifying your email address.',
  yourRequestHasAlreadyBeenReceived: 'Your request has already been received.',
  invalidBirthDate: 'You entered an invalid date in the date of birth field.',
  birthDataUpdateError: 'There was a problem updating your date of birth. Your transactions cannot be continued.',
  identityNumberConfirmed: 'Your identity number has been approved.',
  identityNumberConfirmError: 'There was a problem confirming your ID number.',
  male: 'Male',
  female: 'Female',
  personalAccessTokens: 'Personal Access Tokens',
  personalAccessToken: 'Personal Access Token',
  expireDate: 'Expire Date',
  recordNo: 'Record No',
  doYouWantToDelete: 'Do you want to delete?',
  successfullyRemoved: 'Successfully removed',
  addNewToken: 'Add New Token',
  create: 'Create',
  tokenSuccessResult: 'The token information you can use in your transactions is shared below. Please save the information.',
  teknosaPaymentSuccessMessage: 'Your transaction has been completed successfully. You can start using it immediately by logging in to {0} with your account information sent to your e-mail address.',
  contactSalesConsultant: 'Contact Sales Consultant',
  salesConsultant: 'Sales Consultant',
  from: 'From',
  to: 'To',
  invoiceNo: 'Invoice number',
  isOldInvoice: 'Is Old Invoice?',
  identityIsRequired: 'Your ID Number is required',
  birthDateIsRequired: 'Birthdate is required',
  birthDateRequiredFormat: 'Enter the date of birth as month/day/year.',
  newTicket: 'New Tickets',
  newTicketsAvailable: 'New tickets available!',
  serviceListUsed: 'Service List Used',
  resourceAmount: 'Resource Amount',
  monthName1: 'January',
  monthName2: 'February',
  monthName3: 'March',
  monthName4: 'April',
  monthName5: 'May',
  monthName6: 'June',
  monthName7: 'July',
  monthName8: 'August',
  monthName9: 'September',
  monthName10: 'October',
  monthName11: 'November',
  monthName12: 'December',
  week: 'Week',
  serversAndServices: 'My Servers and Services',
  currencyChangeRequestTitle: 'Currency change request',
  currencyChangeRequestMessage: 'Dear Sir, I want to change the main currency I use in the system as follows. I will be glad if you help.',
  youNeedToLogInToContinue: 'You need to log in again to continue your operation.',
  present: 'Current',
  destination: 'Target',
  copied: 'Kopyalandı!',
  joinTheCampaign: 'Join The Campaign',
  freeProxyNatDNSService: 'Free Proxy-Nat-DNS service',
  freeProxyNatDNSServiceInfo: 'Get Leo One & Leo Star server, use Proxy-Nat-DNS services free of charge for 1 year.',
  freeProxyNatDNSServiceInfoEndDate: '* The campaign is valid until 30.04.2021.',
  enterYourCurrency: 'Enter your currency',
  addresses: 'My Addresses',
  addNewAddress: 'Add New Address',
  updateAddress: 'Update Address',
  addressName: 'Address Name',
  address: 'Address',
  city: 'City',
  is_invoice_address: 'Is invoice address?',
  doYouWantToDeleteSelectedAddress: 'Do you want to delete your selected address?',
  doYouWantToDeleteAddress: 'Are you sure do want to delete it?',
  save: 'Save',
  deletedAddressSuccess: 'Address was deleted successfully.',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  state: 'State',
  postCode: 'Post Code',
  addedAddressSuccess: 'Address added successfully.',
  updatedAddressSuccess: 'Address updated successfully.',
  vat: 'VAT',
  notRegisteredAddress: 'You do not have a registered billing address. Please select an address from the list.',
  notRegisteredAddressAddPlease: 'You do not have a registered address. Please add a new address.',
  closeTicket: 'Close Ticket',
  showSupportDetail: 'Show Support Detail',
  searchForSupport: 'Search for support',
  goToPage: 'Go to page',
  totalRecord: 'Total Record',
  filters: 'Filters',
  searchForTicketNumber: 'Search for ticket number',
  myAccount: 'My Account',
  login: 'Login',
  register: 'Register',
  callback: 'Callback',
  pageNotFound: 'Page Not Found',
  selectPhoneCode: 'Select phone code',
  giftPriceTitle: 'Login with your social accounts and<br /> <b>reedem your $100</b>',
  giftPrice: '$100 GIFT',
  giftPriceInfo: 'Your $100 gift, which you can use in all transactions, will be transferred to your account after registration.',
  or: 'or',
  loginWith: 'Login with {0}',
  notTurkishCitizen: 'I am not a Turkish Citizen.',
  teamManagement: 'Team Management',
  teams: 'Teams',
  teamName: 'Team Name',
  doYouWantToDeleteSelectedTeams: 'Do you want to delete your selected teams?',
  deletedTeamSuccess: 'Team Successfully Removed!',
  createNewTeam: 'Create New Team',
  addedTeamSuccess: 'Team added successfully.',
  updatedTeamSuccess: 'Team updated successfully.',
  teamManagementDetail: 'Team Management Detail',
  members: 'Members',
  addNewMembers: 'Add New Members',
  doYouWantToDeleteSelectedMembers: 'Do you want to delete your selected members?',
  deletedMemberSuccess: 'Member Successfully Removed!',
  birthDateRequiredFormatPlaceholder: 'month/day/year',
}