export default {
  hello: 'Hi',
  welcomeToPlusClouds: 'Welcome to Plusclouds',
  welcomeToPlusGrowth: 'Welcome to Plusgrowth',
  welcomeText: 'Welkom',
  welcomeContentPlusClouds:
    'Welkom op het PlusClouds platform, we zijn erg blij dat je hier bij ons bent. We zien dat u geïnteresseerd bent in sales en marketing technologieën. Als u vragen heeft, neem dan contact op om de meest geschikte oplossingen u aan te bieden.',
  welcomeContentPlusGrowth:
    'Welkom op het PlusGrowth platform, we zijn erg blij dat je hier bij ons bent. We zien dat u geïnteresseerd bent in sales en marketing technologieën. Als u vragen heeft, neem dan contact op om de meest geschikte oplossingen u aan te bieden.',
  hereIsOurNumber: 'Hier is het nummer waar u ons kunt bereiken:',
  niceWorkingDay: 'Fijne werkdag,',
  sincerely: 'met vriendelijke groet.',
  welcomeExperienceText:
    'We hebben enkele goedkeuringen nodig om uw ervaring te verbeteren.',
  selectLanguage: 'Selecteer taal',
  mailVerification: 'E-mailverificatie',
  gsmVerification: 'Gsm-verificatie',
  identityVerification: 'identiteit verificatie',
  welcomePermissionText:
    'Als u wilt, kan ons ondersteuningsteam contact met u opnemen door deze stappen te doorlopen. Overslaan en help.',
  skipSteps: 'Sla deze stappen over',
  customerRepresentative: 'Uw klantvertegenwoordiger',
  verifyYourEmailAddress: 'Verifieer uw e-mailadres',
  confirmEmailInfoText:
    'Activeer uw e-mail door de code in te voeren die we naar uw e-mailadres sturen.',
  confirmEmailConfirmationCode: 'Is de bevestigingscode niet aangekomen?',
  tryAgain: 'Probeer het nog eens',
  confirm: 'Bevestigen',
  confirmationCode: 'Bevestigingscode',
  sendConfirmationCode: 'Stuur een bevestigingscode',
  saveAndSendConfirmationCode: 'Bewaar en verzend bevestigingscode',
  verificationYourGSM: 'Verifieer uw gsm',
  pleaseEnterGSMNo: 'Voer uw gsm-nummer in',
  verifyYourIdentityNumber: 'Verifieer uw identiteitsnummer',
  verifyYourIdentityNumberInfoText:
    'Bevestig uw account door uw identiteitsnummer in te voeren',
  letUsCallYou: 'Laat u terugbellen',
  letUsCallYouInfoText:
    'We zien dat je de stappen wilde doorlopen. Geen probleem. Als u uw telefoonnummer achterlaat, neemt ons ondersteuningsteam contact met u op.',
  chooseDay: 'Kies een dag',
  chooseTime: 'Kies een tijd',
  success: 'Succes',
  successful: 'Succesvol!',
  error: 'Foutje',
  letUsCallYouSuccessText:
    'We hebben uw verzoek. Ons ondersteuningsteam zal zo snel mogelijk contact met u opnemen en de nodige ondersteuning bieden.',
  userProfile: 'Gebruikers profiel',
  signOut: 'Afmelden',
  general: 'Algemeen',
  payment: 'Betaling',
  creditCard: 'Credit Card',
  creditCards: 'Credit Cards',
  noCreditCardInfo: 'U heeft uw creditcardgegevens nog niet met ons gedeeld!',
  addPaymentMethod: 'Betaalmethode toevoegen',
  addNewCard: 'Nieuwe kaart toevoegen',
  cardType: 'TYPE',
  cardName: 'CARD NAME',
  ending: 'ENDING',
  isValid: 'IS VALID',
  isDefault: 'IS DEFAULT',
  default: 'Standaard',
  no: 'Nee',
  valid: 'Geldig',
  invalid: 'Ongeldig',
  verifyCreditCard: 'Verifieer uw creditcard.',
  noDataAvailable: 'Geen data beschikbaar',
  verifyCreditCardTitle: 'Account Verificatie',
  verifyCreditCardSubTitle: 'Om te verifiëren dat de kaart van u is, voert u in het onderstaande veld de waarde in tussen 0-1 TL die van uw kaart is getrokken. (Het opgenomen bedrag wordt op uw account bijgeschreven.)',
  verifyCreditCardSecondSubTitle:'Verification should be done within 1 minute. If your transaction exceeds this period, you must delete your card and re-identify it.',
  amount: 'Bedrag',
  verify: 'Verifieer',
  invalidAmount: 'Ongeldig bedrag.',
  verifyCreditCardSuccess: 'Controleer uw credit card succes!',
  anErrorHasOccurred:
    'Er is een fout opgetreden, probeer het later opnieuw of neem contact op met uw beheerder.!',
  cardHolderName: 'Naam kaarthouder',
  cardNumber: 'kaart nummer',
  cardInformation: 'Kaart informatie',
  expDate: 'Exp Date',
  cvv: 'CVV',
  addCard: 'Voeg toe',
  invalidCreditCardHolderName: 'Ongeldige naam creditcardhouder',
  invalidCreditCardNumber: 'Ongeldig creditcard nummer',
  invalidExpirationDate: 'Ongeldige vervaldatum',
  invalidCVCNumber: 'Ongeldig CVC Nummer',
  addedCreditCardSuccess: 'Uw creditcard is succesvol toegevoegd! U moet het verificatieproces binnen 1 minuut voltooien.',
  billingHistory: 'Factureringsgeschiedenis',
  pay: 'Betalen',
  payNow: 'betaal nu',
  paid: 'Betaald',
  payInvoice: 'Betaal Factuur',
  selectedInvoiceAmount: 'Factuurbedrag geselecteerd',
  pleaseSelectYourCreditCard: 'Selecteer uw creditcard',
  yourBillingPaymentIsSuccessful: 'Uw factuur is betaald!',
  memberSince: 'Lid sinds',
  accountType: 'Account Type',
  yourAccountType: 'Uw accounttype',
  updateAccountTypeText: 'Selecteer of update uw accounttype',
  pleaseSelectAccountType: 'Selecteer een accounttype',
  saveChanges: 'Wijzigingen opslaan',
  generalSettings: 'Algemene instellingen',
  basic: 'Basis',
  nameSurname: 'Naam Achternaam ',
  enterNameSurname: 'Voer voornaam achternaam in',
  birthdate: 'Geboortedatum',
  identityNumber: 'Identeits nummer',
  okey: 'OK',
  password: 'Wachtwoord',
  enterYourCurrentPassword: 'voer uw huidige wachtwoord in',
  emailAddress: 'Email adres',
  enterEmailAddress: 'Vul email adres in',
  newPassword: 'nieuw paswoord',
  enterYourNewPassword: 'Voer je nieuwe wachtwoord in',
  newPasswordAgain: 'Opnieuw nieuw wachtwoord',
  enterYourNewPasswordAgain: 'Voer je nieuwe wachtwoord nogmaals in',
  gender: 'Geslacht',
  pleaseSelectGender: 'Selecteer een geslacht',
  birthDate: 'Geboortedatum',
  preferences: 'Voorkeuren',
  language: 'Taal',
  pleaseSelect: 'Selecteer alstublieft',
  currency: 'Valuta',
  updatedGeneralSettingsSuccess: 'Uw algemene instellingen zijn bijgewerkt!',
  newHere: 'Nieuw hier?',
  createAnAccount: 'Account aanmaken',
  loginWithPlusClouds: 'Log in met PlusClouds',
  loginWithPlusGrowth: 'Log in met PlusGrowth',
  pleaseWaitingRedirectToBack:
    'Een ogenblik geduld, u wordt teruggeleid naar de Leo Console.',
  alreadyHaveAnAccount: 'U heeft al een account?',
  signIn: 'Aanmelden',
  fullname: 'Uw voor en achternaam',
  fullnameIsRequired: 'Volledige naam is vereist',
  emailIsRequiredInfoText: 'E-mail is vereist en een geldig e-mailadres.',
  passwordIsRequired: 'Een wachtwoord is verplicht.',
  passwordConfirmation: 'Wachtwoordbevestiging',
  passwordsMustBeIdentical: 'Wachtwoorden moeten identiek zijn.',
  createFreeAccount: 'Maak een gratis account aan',
  score: 'Score',
  fair: 'Billijk',
  good: 'Goed',
  veryStrong: 'Heel sterk',
  eightCharactersMinimum: 'minimaal 8 karakters',
  oneNumber: 'Een nummer',
  oneSpecialCharacter: 'Een bijzonder karakter',
  oneUpper: '1 uppercase letter',
  oneLower: '1 lowercase letter',
  passwordReset: 'Password Reset',
  didYouComeWrong: 'Did you come wrong?',
  confirmCodeForEmail: 'Bevestigingscode ontvangen in uw e-mail',
  confirmCodeForPhone: 'Bevestigingscode die u op uw telefoon heeft ontvangen',
  confirmCodeForEmailRequired:
    'Er is een bevestigingscode vereist die naar uw e-mailadres is verzonden',
  confirmCodeForPhoneRequired:
    'Bevestigingscode die op uw telefoon is ontvangen, is vereist',
  phoneIsRequired: 'Telefoonveld is verplicht',
  phoneCodeIsRequired: 'Telefooncodeveld is verplicht',
  confirmCodeForEmailIsAccepted: 'De bevestigingscode die naar uw e-mail is verzonden, is geaccepteerd',
  confirmCodeForPhoneIsAccepted: 'De bevestigingscode die naar uw telefoon is verzonden, is geaccepteerd',
  captcha: 'Captcha',
  subscribe: 'Abonneren',
  subscription: 'Abonnement',
  piece: 'Stuk',
  infrastructureResource: 'Infrastructuurbron',
  registered: 'Geregistreerd',
  unregistered: 'Niet geregistreerd',
  packets: 'Pakketten',
  monthlyPackagesWithandWithoutCommitment: 'Maandelijkse pakketten met en zonder verplichting',
  monthly: 'Maandelijks',
  discount: 'Korting',
  free: 'Vrij',
  cancel: 'Annuleren',
  youMustAcceptTerm: 'U moet de gebruiksvoorwaarden accepteren.',
  acceptTermWithUrl: 'Ik heb de gebruiksvoorwaarden gelezen en ga hiermee akkoord.',
  confirmOfPurchase: 'AANKOOP BEVESTIGEN',
  yourPaymentOptions: 'Uw betalingsmogelijkheden',
  yourPackage: 'Jouw pakket',
  youCanMakePurchaseByChoosingCards: 'U kunt uw aankoop doen door een van de onderstaande kaarten te kiezen.',
  freeCloudService: 'Gratis Cloud Service',
  teknosaBannerSubtitle: 'Sla alle bestanden op uw mobiele apparaat en computer veilig op in de cloud, wat gratis is, exclusief voor Teknosacell-klanten.',
  youMustHaveLeastOneCreditCard: 'U moet ten minste één creditcard hebben gedefinieerd om een transactie te kunnen uitvoeren.',
  allTickets: 'Alle Tickets',
  open: 'Openen',
  pending: 'In afwachting',
  closed: 'Gesloten',
  searchForPerson: 'Zoeken naar persoon',
  allCategories: 'Alle categorieën',
  category: 'Categorie',
  selectCategory: 'Selecteer Categorie',
  myTickets: 'Mijn Tickets',
  openTicket: 'Open Ticket',
  ticketNumber: 'Ticket Nummer',
  latestActivityOnYourAccountIsDisplayed: 'De laatste activiteitengeschiedenis van uw account wordt hieronder weergegeven!',
  priority: 'Prioriteit',
  title: 'Titel',
  createdDate: 'Aanmaakdatum',
  status: 'Status',
  support: 'Ondersteuning',
  subject: 'Onderwerpen',
  priority: 'Prioriteit',
  selectPriority: 'Kies een prioriteit',
  message: 'Bericht',
  attachment: 'Hechting',
  chooseFile: 'Kies bestand..',
  dropFileHere: 'Zet het bestand hier neer..',
  low: 'LAAG',
  medium: 'MEDIUM',
  high: 'HOOG',
  addedTicketSuccess: 'Ticket Succes Toegevoegd!',
  addedAnswerSuccess: 'Antwoordsucces toegevoegd!',
  writeSomething: 'Schrijf iets...',
  send: 'Sturen',
  writeReplyOrDragFilesHere: 'Schrijf uw antwoord of sleep bestanden hierheen',
  getFreeStash: 'Get Free Stash',
  contactMe: 'CONTACT ME',
  canWeHelpYou: 'How can we help you?',
  contactUs: 'Contact Us',
  detail: 'Detail',
  couldYouGiveUs: 'Could you give us information about the subject you want to reach us so that we can serve you better?',
  sendMessageSuccess: 'Your message has been sent successfully.',
  sendCustomerMessageSuccess: 'We have received your request successfully. Our team will contact you as soon as possible on the subject you specified.',
  teknosaGiftBannerText: 'Gratis en vrijblijvend tot 20 GB cloudruimte, exclusief voor Teknosacell klanten.',
  subscribeForFree: 'Abonneer Je Gratis',
  languageSelection: 'Taal Selectie',
  language_tr: 'Türkçe',
  language_en: 'English',
  language_nl: 'Dutch',
  name: 'Naam',
  surname: 'Achternaam',
  enterName: 'Naam invoeren',
  enterSurname: 'Voer achternaam in',
  enterYourBirthdate: 'Vul alsjeblieft je geboortedatum in (MM/DD/YYYY)',
  changeRequest: 'Verzoek wijzigen',
  yourGSMNumber: 'Uw gsm-nummer',
  update: 'Bijwerken',
  updateYourGSM: 'Update uw gsm-nummer',
  updateYourEmail: 'Update uw e-mailadres',
  pleaseEnterEmailAddress: 'Vul alstublieft uw e-mail adres in',
  country: 'Land',
  search: 'Zoeken',
  usageAgreement: 'Gebruiksovereenkomst',
  searchForTitle: 'Zoek op titel',
  allPriorities: 'Alle prioriteiten',
  dateFilter: 'Datum filter',
  startDate: 'Start datum',
  endDate: 'Eind datum',
  enterStartDate: 'Voer startdatum in (MM/DD/YYYY)',
  enterEndDate: 'Voer einddatum in (MM/DD/YYYY)',
  clear: 'Doorzichtig',
  deletedCreditCardSuccess: 'Creditcard succesvol verwijderd!',
  yes: 'Ja',
  pleaseConfirm: 'Bevestig alstublieft',
  doYouWantToDeleteCreditCard: 'Wilt u uw creditcard verwijderen?',
  updateCard: 'Update Kaart',
  updatedCreditCardSuccess: 'Creditcard succesvol bijgewerkt!',
  deleteSelected: 'Verwijder Geselecteerde',
  noSelection: 'U moet een keuze maken om de operatie voort te zetten.',
  doYouWantToDeleteSelectedCreditCard: 'Wilt u uw geselecteerde creditcards verwijderen?',
  yourPasswordHasBeenChangedSuccessfully: 'Je wachtwoord is met succes veranderd',
  newPasswordRequired: 'Nieuw wachtwoord is vereist',
  confirmation: 'Bevestiging',
  confirmationCodeIsInvalid: 'Bevestigingscode is ongeldig',
  subscriptionConfirmation: 'Abonnementsbevestiging',
  freeSubscriptionPackageDefinedForYourUse: 'Met het door u gekozen abonnementspakket wordt gratis cloudruimte gedefinieerd voor uw gebruik.',
  acceptfreeTermWithUrl: 'Ik heb de gratis gebruiksvoorwaarden gelezen, ik ga akkoord.',
  confirmationCodeSentAgain: 'De bevestigingscode is opnieuw verzonden.',
  receiveYourCodeSendItAgain: 'Geen bevestigingscode ontvangen? Opnieuw versturen.',
  passwordIsNotValid: 'Wachtwoord is niet geldig',
  confirmCodeForEmailDesc: 'We hebben een e-mail naar uw e-mailadres gestuurd zoals in onderstaand voorbeeld. U moet de \'Verificatiecode\' informatie in deze e-mail invoeren in het relevante veld. ',
  confirmCodeForPhoneDesc: 'We hebben een sms gestuurd naar uw geregistreerde telefoonnummer zoals in het onderstaande voorbeeld. U moet de \'Verificatiecode\' informatie in deze sms-tekst invoeren in het relevante veld.',
  completeTheProcess: 'Rond het proces af',
  birthDateWithFormat: 'Geboortedatum (MM/DD/YYYY)',
  beingRedirectedLoginScreen: 'U wordt doorgestuurd naar het inlogscherm..',
  goToLogin: 'Ga naar Inloggen',
  timeLeft: 'Tijd over',
  second: 'Tweede',
  actions: 'Acties',
  action: 'Acties',
  date: 'Datum',
  description: 'Omschrijving',
  unitPrice: 'Stuksprijs',
  total: 'Totaal',
  paymentStatus: 'Betalings Status',
  errorDetail: 'Fout detail',
  pleaseLogin: 'Log alsjeblieft in',
  yourAccountCreated: 'Je account is succesvol aangemaakt. U wordt omgeleid...',
  confirmationCodeHasBeenSentPhone: 'De bevestigingscode is naar uw telefoon gestuurd',
  yourPhoneConfirmed: 'Uw telefoonnummer is bevestigd.',
  yourPhoneConfirmError: 'Er is een probleem opgetreden bij het verifiëren van uw telefoonnummer.',
  confirmationCodeHasBeenSentMail: 'Bevestigingscode is naar uw e-mailadres verzonden',
  yourMailConfirmed: 'Uw e-mailadres is bevestigd.',
  yourMailConfirmError: 'Er is een probleem opgetreden bij het verifiëren van uw e-mailadres.',
  yourRequestHasAlreadyBeenReceived: 'Uw aanvraag is al ontvangen.',
  invalidBirthDate: 'U heeft een ongeldige datum ingevoerd in het geboortedatumveld.',
  birthDataUpdateError: 'Er is een probleem opgetreden bij het bijwerken van uw geboortedatum. Uw transacties kunnen niet worden voortgezet.',
  identityNumberConfirmed: 'Uw identiteitsnummer is goedgekeurd.',
  identityNumberConfirmError: 'Er is een probleem opgetreden bij het bevestigen van uw ID-nummer.',
  male: 'Male',
  female: 'Female',
  personalAccessTokens: 'Persoonlijke toegangstokens',
  personalAccessToken: 'Persoonlijk toegangstoken',
  expireDate: 'Vervaldatum',
  recordNo: 'Noteer Nr',
  doYouWantToDelete: 'Wil je stoppen?',
  successfullyRemoved: 'Succesvol verwijderd',
  addNewToken: 'Voeg Een Nieuw Token Toe',
  create: 'Creëren',
  tokenSuccessResult: 'De tokeninformatie die u in uw transacties kunt gebruiken, wordt hieronder gedeeld. Sla de informatie op.',
  teknosaPaymentSuccessMessage: 'Uw transactie is succesvol afgerond. U kunt het onmiddellijk gebruiken door in te loggen op {0} met uw accountinformatie naar uw e-mailadres.',
  contactSalesConsultant: 'Neem Contact Op Met Verkoopadviseur',
  salesConsultant: 'Verkoopadviseurt',
  from: 'Van',
  to: 'Naar',
  invoiceNo: 'Invoice number',
  isOldInvoice: 'Is Old Invoice?',
  identityIsRequired: 'Uw ID-nummer is vereist',
  birthDateIsRequired: 'Geboortedatum is vereist',
  birthDateRequiredFormat: 'Voer de geboortedatum in als maand/dag/jaar.',
  newTicket: 'New Tickets',
  newTicketsAvailable: 'New tickets available!',
  serviceListUsed: 'Service List Used',
  resourceAmount: 'Resource Amount',
  monthName1: 'January',
  monthName2: 'February',
  monthName3: 'March',
  monthName4: 'April',
  monthName5: 'May',
  monthName6: 'June',
  monthName7: 'July',
  monthName8: 'August',
  monthName9: 'September',
  monthName10: 'October',
  monthName11: 'November',
  monthName12: 'December',
  week: 'Week',
  serversAndServices: 'My Servers and Services',
  currencyChangeRequestTitle: 'Currency change request',
  currencyChangeRequestMessage: 'Dear Sir, I want to change the main currency I use in the system as follows. I will be glad if you help.',
  youNeedToLogInToContinue: 'U moet opnieuw inloggen om door te gaan met uw operatie.',
  present: 'Current',
  destination: 'Target',
  copied: 'Copied!',
  joinTheCampaign: 'Join The Campaign',
  freeProxyNatDNSService: 'Free Proxy-Nat-DNS service',
  freeProxyNatDNSServiceInfo: 'Get Leo One & Leo Star server, use Proxy-Nat-DNS services free of charge for 1 year.',
  freeProxyNatDNSServiceInfoEndDate: '* The campaign is valid until 30.04.2021.',
  enterYourCurrency: 'Enter your currency',
  addresses: 'My Addresses',
  addNewAddress: 'Add New Address',
  updateAddress: 'Update Address',
  addressName: 'Address Name',
  address: 'Address',
  city: 'City',
  is_invoice_address: 'Is invoice address?',
  doYouWantToDeleteSelectedAddress: 'Wilt u uw geselecteerde adres verwijderen?',
  doYouWantToDeleteAddress: 'Are you sure do want to delete it?',
  save: 'Save',
  deletedAddressSuccess: 'Address was deleted successfully.',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  state: 'State',
  postCode: 'Post Code',
  addedAddressSuccess: 'Address added successfully.',
  updatedAddressSuccess: 'Address updated successfully.',
  vat: 'VAT',
  notRegisteredAddress: 'You do not have a registered billing address. Please select an address from the list.',
  notRegisteredAddressAddPlease: 'You do not have a registered address. Please add a new address.',
  closeTicket: 'Close Ticket',
  showSupportDetail: 'Show Support Detail',
  searchForSupport: 'Search for support',
  goToPage: 'Go to page',
  totalRecord: 'Total Record',
  filters: 'Filters',
  searchForTicketNumber: 'Search for ticket number',
  myAccount: 'My Account',
  login: 'Login',
  register: 'Register',
  callback: 'Callback',
  pageNotFound: 'Page Not Found',
  selectPhoneCode: 'Selecteer telefooncode',
  giftPriceTitle: 'Log in met uw sociale accounts en<br /> <b>wissel uw $ 100 in</b>',
  giftPrice: '$100 GIFT',
  giftPriceInfo: 'Uw gift van $ 100, die u bij alle transacties kunt gebruiken, wordt na registratie overgemaakt naar uw account.',
  or: 'of',
  loginWith: 'Inloggen met {0}',
  notTurkishCitizen: 'I am not a Turkish Citizen.',
  teamManagement: 'Team Management',
  teams: 'Teams',
  teamName: 'Team Name',
  doYouWantToDeleteSelectedTeams: 'Wilt u uw geselecteerde teams verwijderen?',
  deletedTeamSuccess: 'Team succesvol verwijderd!',
  createTeam: 'Create New Team',
  addedTeamSuccess: 'Team added successfully.',
  updatedTeamSuccess: 'Team updated successfully.',
  teamManagementDetail: 'Team Management Detail',
  members: 'Members',
  addNewMembers: 'Add New Members',
  doYouWantToDeleteSelectedMembers: 'Do you want to delete your selected members?',
  deletedMemberSuccess: 'Member Successfully Removed!',
  birthDateRequiredFormatPlaceholder: 'month/day/year',
}