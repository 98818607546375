import { VERIFY_REGISTERED_USER, LOGOUT } from '@/core/services/store/auth.module'

import JwtService from '@/core/services/jwt.service'

export default function verifyUser({ next, store, router }) {
  const currentUser = JwtService.getCurrentUser()
  const token = JwtService.getToken()

  if (currentUser) {
    store
      .dispatch(VERIFY_REGISTERED_USER, currentUser.account.user.email)
      .then(res => {
        if (res.is_email_validated && token == 'Empty No Token') {
          store.dispatch(LOGOUT)
          router.push({ name: 'login' })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return next()
}